<template>
  <div id="polyline-chart"></div>
</template>

<script>
import DataSet from '@antv/data-set';
import { Chart } from '@antv/g2';
import { mapGetters } from 'vuex'

import { freightVolumeDistribution } from '@/api/home.js'
export default {
  data() {
    return {
      dataList: []
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    })
  },
  methods: {
    chartInit() {
      let _this = this
      let height = window.getComputedStyle(document.getElementById('polyline-chart')).height
      const { DataView } = DataSet;
      const dv = new DataView().source(_this.dataList);
      dv.transform({
        type: 'map',
        callback(row) {
          row.percent = row.proportion;
          return row;
        },
      });
      const data = dv.rows;
      const chart = new Chart({
        container: 'polyline-chart',
        autoFit: true,
        height: height,
        padding: [20, 120, 95],
      });
      chart.data(data);
      chart.axis(false);
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li style="margin-bottom:4px;list-style-type:none;padding: 0;">' +
          '<span style="background-color:{color};" class="g2-tooltip-marker"></span>' +
          '<span style="padding-left: 16px;line-height: 16px;">{province}</span>' +
          '<span style="padding-left: 16px;line-height: 16px;">{amount}</span>' +
          '<span style="padding-left: 16px;line-height: 16px;">{percent}</span>' +
          '</li>',
      });
      chart
        .coordinate('rect')
        .transpose()
        .scale(1, -1);
      chart
        .interval()
        .adjust('symmetric')
        .position('province*percent')
        .shape('funnel')
        .color('province', ['#0050B3', '#1890FF', '#40A9FF', '#69C0FF', '#BAE7FF'])
        .label(
          'province*amount*percent',
          (province, amount, percent) => {
            return {
              content: `${province} ${amount}  ${(percent * 100).toFixed(2)}%`,
            };
          },
          {
            offset: 35,
            labelLine: {
              style: {
                lineWidth: 1,
                stroke: 'rgba(0, 0, 0, 0.15)',
              },
            },
          }
        )
        .tooltip('province*amount*percent', (province, amount, percent) => {
          return {
            province: province,
            percent: (percent * 100).toFixed(2) + '%',
            amount,
          };
        })
        .animate({
          appear: {
            animation: 'fade-in'
          },
          update: {
            annotation: 'fade-in'
          }
        });

      chart.interaction('element-active');

      chart.on('beforepaint', () => {
        chart.annotation().clear(true);
        const chartData = chart.getData();
        // 中间标签文本
        chartData.forEach((obj) => {
          chart.annotation().text({
            top: true,
            position: {
              province: obj.province,
              percent: 'center',
            },
            content: (obj.percent * 100).toFixed(2) + '%', // 显示的文本内容
            style: {
              stroke: null,
              fill: '#fff',
              textAlign: 'center',
            },
          });
        });
      });

      chart.render();
    },
    async getDataInfo() {
      try {
        const res = await freightVolumeDistribution({ cnorId: this.userInfo.cnorId })
        this.dataList = res.data
        this.chartInit()
      } catch (error) {
        console.log(error)
      }
    }
  },
  created() {
    
    this.getDataInfo()
  }

}
</script>

<style>
#polyline-chart {
  width: 100%;
  height: 100%;
}
</style>