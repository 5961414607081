<template>
  <div class="data-count lc-flex">
    <div class="count-item lc-flex-column lc-flex-col-center lc-space-between">
      <div class="count-time">近一年</div>
      <icon-svg class="icon" iconClass="icondingdan"></icon-svg>
      <span class="count">{{count.orderCount || 0}}</span>
      <span class="count-label">总运单数</span>
    </div>
    <div class="count-item lc-flex-column lc-flex-col-center lc-space-between">
      <div class="count-time">近一年</div>
      <icon-svg class="icon" iconClass="iconpeisong"></icon-svg>
      <span class="count">{{count.weightCount || 0}}</span>
      <span class="count-label">总吨位</span>
    </div>
    <div class="count-item lc-flex-column lc-flex-col-center lc-space-between">
      <div class="count-time">近一年</div>
      <icon-svg class="icon" iconClass="iconzhengding"></icon-svg>
      <span class="count">{{count.complaintCount || 0}}</span>
      <span class="count-label">未签收运单</span>
    </div>
    <div class="count-item lc-flex-column lc-flex-col-center lc-space-between">
      <div class="count-time">近30日</div>
      <icon-svg class="icon" iconClass="iconjiesuan2"></icon-svg>
      <span class="count">{{count.rate || 0}}%</span>
      <span class="count-label">准点率</span>
    </div>
  </div>
</template>

<script>
import IconSvg from '@/components/Common/IconSvg.vue'
import { mapGetters } from 'vuex'

import { getWayBillInfo, zeroCarloadVolumeTrend } from '@/api/home.js'
export default {
  components: { IconSvg },
  data() {
    return {
      count: {
        orderCount: 0,
        weightCount: 0,
        complaintCount: 0,
        rate: 0,
      }
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    })
  },
  mounted() {
    this.getWayBillInfo()
    // this.anomalousEvent()
  },
  methods: {
    async getWayBillInfo() {
      try {
        const res = await getWayBillInfo({ cnorId: this.userInfo.cnorId })
        this.count.orderCount = res.data.wayId
        this.count.weightCount = res.data.instGrossWei
        this.count.complaintCount = res.data.unReceipted
        this.count.rate = res.data.onTimePerformance
      } catch (error) {
        console.log(error)
      }
    },
    async anomalousEvent() {
      try {
        const res = await zeroCarloadVolumeTrend({ cnorId: this.userInfo.cnorId })
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },

  }

}
</script>

<style lang="scss" scoped>
.data-count {
  width: 100%;
  height: 120px;
  color: #666;
  font-size: 12px;
}
.count-item {
  flex: 1;
  border: 1px solid #f2f2f2;
  margin-left: 10px;
  padding: 20px 0;
  position: relative;
  &:last-child {
    margin-right: 10px;
  }
  .count {
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }
  .icon {
    font-size: 20px;
  }
  .count-time {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
</style>