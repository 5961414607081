<!--
 * @Author: xiaoxingliang
 * @Date: 2021-12-18 10:45:53
 * @LastEditTime: 2021-12-20 13:48:25
 * @LastEditors: zhangyanhua
 * @FilePath: \lc_factory\src\views\home\index.vue
-->
<template>
  <div class="home">
    <data-count :count="statistics"></data-count>
    <section class="one lc-flex">
      <div class="chart-1">
        <data-polyline-chart></data-polyline-chart>
      </div>
      <div class="chart-2">
        <data-funnel-chart></data-funnel-chart>
      </div>
    </section>
    <section class="one lc-flex">
      <div class="chart-1">
        <template>
          <el-table
                  :data="tableData"
                  height="100%"
                  border
                  style="width: 100%">
            <el-table-column
                    prop="createDt"
                    label="发生时间"
                    align="center"
                    min-width="100%">
            </el-table-column>
            <el-table-column
                    prop="exceptionTrackingType"
                    label="事件类型"
                    align="center"
                    min-width="50%">
            </el-table-column>
            <el-table-column
                    prop="wayId"
                    label="运单号"
                    align="center"
                    min-width="60%">
            </el-table-column>
            <el-table-column
                    prop="cnorNbr"
                    label="厂家单号"
                    align="center"
                    min-width="70%">
            </el-table-column>
            <el-table-column
                    prop="destNm"
                    label="目的地"
                    align="center">
            </el-table-column>
            <el-table-column
                    prop="instQty"
                    label="数量"
                    align="center"
                    min-width="30%">
            </el-table-column>
            <el-table-column
                    prop="rcvrNm"
                    label="收货单位"
                    align="center">
            </el-table-column>
            <el-table-column
                    prop="rcvrCtcts"
                    label="收货人"
                    align="center"
                    min-width="40%">
            </el-table-column>
          </el-table>
        </template>
      </div>
      <div class="chart-2">
        <data-pie-chart></data-pie-chart>
      </div>
    </section>
    
    
  </div>
</template>

<script>
  import DataPolylineChart from './components/DataPolylineChart.vue';
  import DataFunnelChart from './components/DataFunnelChart.vue';
  import DataPieChart from './components/DataPieChart.vue';
  import DataCount from './components/DataCount.vue';
  import { getTable4ExcepitonInfo } from '@/api/home.js'
  export default {
    name: 'Home',
    components: {
      DataCount,
      DataPolylineChart,
      DataFunnelChart,
      DataPieChart
    },
    data() {
      return {
        statistics:
          {
            orderCount: 3000,
            weightCount: 36254,
            complaintCount: 3,
            rate: 98,

          },
        tableData: []
      };
    },
    watch: {},
    mounted() {
      this.getDataTableInfo()
    },
    methods: {
      async getDataTableInfo() {
        try {
          const res = await getTable4ExcepitonInfo()
          if(res.code == '000'){
            this.tableData = res.data
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
  };
</script>

<style lang="scss" scope>
.home {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  background-color: #ffffff;
  .one {
    padding: 10px;
    width: 100%;
    height: calc((100% - 120px) / 2);
    &:last-child {
      padding-top: 0;
    }
  }
  .chart-1, .chart-3 {
    width: 60%;
    height: 100%;
    border: 1px solid #f2f2f2;
    margin-bottom: 10px;
  }
  .chart-2, .chart-4 {
    width: 40%;
    height: 100%;
    margin-left: 10px;
    border: 1px solid #f2f2f2;
  }
}
</style>
