<template>
  <div id="container"></div>
</template>

<script>
import { Chart } from '@antv/g2';
import { mapGetters } from 'vuex'
import { zeroCarloadVolumeTrend } from '@/api/home.js'

export default {
  data() {
    return {
      datalist:[]
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    })
  },
  methods: {
    chartInit() {
      const chart = new Chart({
        container: 'container',
        autoFit: true,
        height: 500,
      });

      chart.data(this.datalist);
      chart.scale({
        deliveryDtMonth: {
          range: [0, 1],
        },
        amount: {
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      chart.axis('amount', {
        label: {
          formatter: (val) => {
            return val;
          },
        },
      });

      chart
        .line()
        .position('deliveryDtMonth*amount')
        .color('typeName')
        .shape('smooth');

      chart
        .point()
        .position('deliveryDtMonth*amount')
        .color('typeName')
        .shape('circle');
      chart.render();
    },
    async getDataInfo() {
      try {
        const res = await zeroCarloadVolumeTrend({ cnorId: this.userInfo.cnorId })
        if(res.code == '000'){
          this.datalist = res.data;
        }
        this.chartInit()
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    this.getDataInfo()
  }

}
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}
</style>