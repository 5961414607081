import Cookie from 'js-cookie'
import { loginByUserName } from '@/api/login'


const state = () => ({
  get token() {
    return Cookie.get('lc-token')
  },
  set token(value) {
    if (!!value) {
      Cookie.set('lc-token', value)
    } else {
      // 清空当前所有域名下的token
      Cookie.set('lc-token', '')
      Cookie.remove('lc-token')
      // window.location.reload()
    }
  },
  
  get userInfo() {
    return JSON.parse(window.localStorage.getItem('userInfo') || '{}')
  },
  set userInfo(val) {
    window.localStorage.setItem('userInfo', JSON.stringify(val || []))
  },
  get roleMenu() {
    return JSON.parse(window.localStorage.getItem('roleMenu') || '[]')
  },
  set roleMenu(val) {
    window.localStorage.setItem('roleMenu', JSON.stringify(val || []))
  },
  get buttonPermission() {
    return JSON.parse(window.localStorage.getItem('buttonPermission') || '{}')
  },
  set buttonPermission(val) {
    window.localStorage.setItem('buttonPermission', JSON.stringify(val || {}))
  }
})
const mutations = {
  // 设置token
  SET_TOKEN(state, token) {
    state.token = token
  },
  // 用户登出
  LOGOUT(state) {
    state.token = null
  },
  // 设置用户信息
  SET_USER_INFO(state, val) {
    state.userInfo = val
  },
  // 设置权限菜单
  SET_ROLE_MENU(state, val) {
    state.roleMenu = val
  },
  // 设置权限按钮
  SET_BUTTON_PERMISSION(state, val) {
    state.buttonPermission = val
  }
}

const actions = {
  // async getLoginToken({ commit }, form) {
  //   // 请求登录接口
  //   return UserController.getLoginToken(form).then(res => {
  //     if (res && !res.code) {
  //       commit('SET_TOKEN', res)
  //     }
  //     return res
  //   })
  // },

  async login({ commit }, form) {
    // 请求登录接口
    return loginByUserName(form).then(res => {
      if (res) {
        commit('SET_TOKEN', res.data.token)
      }
      return res
    })
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit('LOGOUT')
      resolve()
    })
  },

  // // 根据token获取用户的详细信息
  // async getUserInfoByToken({ commit }) {
  //   return getUserInfoByToken().then(res => {
  //     commit('SET_USER_INFO', res)
  //     return res
  //   })
  // },

  // // 根据token获取用户权限菜单
  // async getUserRoleMenu({ commit }) {
  //   function getMenu(arr) {
  //     let str = arr && JSON.stringify(arr)
  //     return JSON.parse(str)
  //   }
  //   return getRoleMenuByToken().then(res => {
  //     commit('SET_ROLE_MENU', res)
  //     return res
  //   })
  // },

//   // 根据token获取用户权限按钮
//   async getUserRoleButton({ commit }) {
//     return getRoleButtonByToken().then(res => {
//       let btnObj = {}
//       res.forEach(item => {
//         let group = item.split('_')[0]
//         if (!btnObj[group]) {
//           btnObj[group] = []
//         }
//         btnObj[group].push(item)
//       })
//       commit('SET_BUTTON_PERMISSION', btnObj)
//       return res
//     })
//   }
}

// export const getters = {
//   token: state => state.token,
//   roleMenu: state => state.roleMenu,
//   userInfo: state => state.userInfo,
//   buttonPermission: state => state.buttonPermission
// }

export default {
  state,
  mutations,
  actions
}