/*
 * @Author: xiaoxingliang
 * @Date: 2021-12-18 15:09:20
 * @LastEditTime: 2021-12-18 15:34:55
 * @LastEditors: xiaoxingliang
 * @FilePath: \lc_factory\src\utils\http.js
 */
import axios from 'axios';
// import qs from 'qs';
import { Message } from 'element-ui';
import router from '@/router';
import { isFormData } from './util';
import { getToken } from '@/utils/auth.js'
import store from '@/store/index.js'
import {getMD5,dateTimeFormat} from '@/utils/util.js'
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || '/oms/',
  timeout: 20000,
  withCredentials: true,
});
//这里简单列出一些常见的http状态码信息，可以自己去调整配置
const httpCode = {
  400: '请求参数错误',
  401: '权限不足, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  504: '网关超时',
};
//请求拦截
http.interceptors.request.use(
  (config) => {
    if (['post', 'put', 'patch', 'delete'].includes(config.method.toLocaleLowerCase())) {
      // config.transformRequest = [
      //   function (data, headers) {
      //     if (isFormData(data)) return data;
      //     return JSON.stringify(data);
      //   },
      // ];
    }
    if (getToken()) {
      config.headers['lc-token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
	let time = new Date()
	var str = Date.parse(dateTimeFormat(time)).toString()
	var ts = str
	str= str.substr(str.length-6)
	let md5Str = 'ab838c68d01'+str+'44a1f98980eabbc29ce19'
	md5Str = getMD5(md5Str)
	config.headers['sign'] = md5Str
	config.headers['ts'] = ts
    return config;
  },
  (error) => {
    return new Promise.reject(error);
  }
);

//响应拦截
http.interceptors.response.use(
  (response) => {
    if (response.data?.code) {
      if (response.data.code == '000') {
        return Promise.resolve(response.data);
      } else if (response.data.code == '101') {
        Message({
          message: response.data.msg || response.data.message,
          type: 'error',
        });
        console.log(store)
        store.dispatch('logout').then(() => location.reload())
        return Promise.reject(response.data);
      } else {
        Message({
          message: response.data.msg || response.data.message,
          type: 'error',
        });
        return Promise.reject(response.data);
      }
    } else {
      return Promise.resolve(response);
    }
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log('已取消的重复请求：' + error.message);
    }
    if (error.response) {
      const { status, data, config } = error.response;
      let errorTip = status in httpCode ? httpCode[status] : data.msg;
      Message({
        message: errorTip,
        type: 'error',
      });
      // switch (status) {
      //   case 401:
      //     router.push({
      //       path: `/login`,
      //     });
      //     break;
      //   default:
      //     break;
      // }
    }
    return Promise.reject(error);
  }
);

export default http;
