<template>
  <div id="pieChart"></div>
</template>

<script>
import { Chart } from '@antv/g2';

import { mapGetters } from 'vuex'

import { transportationStructure } from '@/api/home.js'

export default {
  data() {
    return {
      dataList: []
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    })
  },
  methods: {
    chartInit() {
      const chart = new Chart({
        container: 'pieChart',
        autoFit: true,
        height: 500,
      });
      chart.data(this.dataList);
      chart.scale('percent', {
        formatter: (val) => {
          val = (val * 100).toFixed(2) + '%';
          return val;
        },
      });
      chart.coordinate('theta', {
        radius: 0.75,
        innerRadius: 0.6,
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl: '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
      });
      // 辅助文本
      // chart
      //   .annotation()
      //   .text({
      //     position: ['50%', '50%'],
      //     content: '主机',
      //     style: {
      //       fontSize: 14,
      //       fill: '#8c8c8c',
      //       textAlign: 'center',
      //     },
      //     offsetY: -20,
      //   })
      //   .text({
      //     position: ['50%', '50%'],
      //     content: '200',
      //     style: {
      //       fontSize: 20,
      //       fill: '#8c8c8c',
      //       textAlign: 'center',
      //     },
      //     offsetX: -10,
      //     offsetY: 20,
      //   })
      //   .text({
      //     position: ['50%', '50%'],
      //     content: '台',
      //     style: {
      //       fontSize: 14,
      //       fill: '#8c8c8c',
      //       textAlign: 'center',
      //     },
      //     offsetY: 20,
      //     offsetX: 20,
      //   });
      chart
        .interval()
        .adjust('stack')
        .position('percent')
        .color('item')
        .label('percent', (percent) => {
          return {
            content: (data) => {
              return `${data.item}: ${(percent * 100).toFixed(2)}%`;
            },
          };
        })
        .tooltip('item*percent', (item, percent) => {
          percent = (percent * 100).toFixed(2) + '%';
          return {
            name: item,
            value: percent,
          };
        });

      chart.interaction('element-active');

      chart.render();
    },
    async getDataInfo() {
      try {
        const res = await transportationStructure({ cnorId: this.userInfo.cnorId })
        // console.log(res)
        this.dataList = [
          {
            item: '整车',
            percent: res.data.theVehicleProportion
          },
          {
            item: '零担',
            percent: res.data.lessThanOneCarloadRate
          }
        ]
        this.chartInit()
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    
    this.getDataInfo()
  }

}
</script>

<style scoped>
#pieChart {
  width: 100%;
  height: 100%;
}

</style>