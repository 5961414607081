/*
 * @Author: xiaoxingliang
 * @Date: 2021-12-18 10:42:23
 * @LastEditTime: 2021-12-23 15:40:55
 * @LastEditors: xiaoxingliang
 * @FilePath: \lc_factory\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/layout/index.vue";
import Login from "@/views/login/index.vue";

import Home from "@/views/home/index.vue"
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };
Vue.use(VueRouter);

const routes = [
  {
    id: "1",
    path: "/layout",
    name: "Layout",
    component: Layout,
    // redirect: '/layout/home',
    children: [
      {
        id: "11",
        path: "/layout/home",
        title: "首页",
        name: "Home",
        icon: "el-icon-s-home",
        component: Home,
        // component: () => import(/*webpackChunkName: "home" */ "@/views/home/index.vue"),
      }
    ],
  },
  {
    id: "2",
    path: "/order",
    title: "订单管理",
    name: "Order",
    icon: "el-icon-s-order",
    component: Layout,
    redirect: '/order/order-management',
    children: [
      {
        id: "22",
        path: "/order/add-order",
        title: "客户下单",
        name: "AddOrder",
        icon: "el-icon-edit-outline",
        component: () => import(/*webpackChunkName: "order" */ "@/views/order/add-order/index.vue"),
      },
      {
        id: "21",
        path: "/order/order-management",
        title: "订单管理",
        name: "OrderManagement",
        icon: "el-icon-goods",
        component: () => import(/*webpackChunkName: "order" */ "@/views/order/order-management/index.vue"),
      },
    ]
  },
  {
    id: "3",
    path: "/track",
    name: "Track",
    title: "跟踪管理",
    icon: "el-icon-s-promotion",
    component: Layout,
    children: [
      {
        id: "31",
        path: "/track/track-list",
        title: "跟踪列表",
        name: "TrackList",
        icon: "el-icon-s-promotion",
        component: () => import(/*webpackChunkName: "track" */ "@/views/track/track-list/index.vue"),
      },
      {
        id: "54",
        path: "/track/waybill-appraise",
        title: "运单评价",
        name: "WaybillAppraise",
        icon: "el-icon-chat-dot-square",
        component: () => import(/*webpackChunkName: "track" */ "@/views/track/waybill-appraise/index.vue"),
      },
      // {
      //   id: "32",
      //   path: "/track/appraise",
      //   title: "客户评价",
      //   name: "Appraise",
      //   icon: "el-icon-s-claim",
      //   component: () => import(/*webpackChunkName: "track" */ "@/views/track/appraise/index.vue"),
      // },
      {
        id: "33",
        path: "/track/dashboard",
        title: "跟踪看板",
        name: "Dashboard",
        icon: "el-icon-s-data",
        component: () => import(/*webpackChunkName: "track" */ "@/views/track/dashboard/index.vue"),
      },
      {
        id: "34",
        path: "/track/abnormal",
        title: "异常事件",
        name: "Abnormal",
        icon: "el-icon-s-release",
        component: () => import(/*webpackChunkName: "track" */ "@/views/track/abnormal/index.vue"),
      },
      {
        id: "23",
        path: "/track/data-analysis",
        title: "数据分析",
        name: "DataAnalysis",
        icon: "el-icon-s-platform",
        component: () => import(/*webpackChunkName: "track" */ "@/views/track/data-analysis/index.vue"),
      },
    ]
  },
  {
    id: "4",
    path: "/receipt",
    name: "Receipt",
    title: "回单管理",
    icon: "el-icon-s-management",
    component: Layout,
    children: [
      {
        id: "41",
        path: "/receipt/receipt-list",
        title: "回单管理",
        name: "ReceiptList",
        icon: "el-icon-s-management",
        component: () => import(/*webpackChunkName: "receipt" */ "@/views/receipt/receipt-list/index.vue"),
      },
    ]
  },
  {
    id: "5",
    path: "/consult",
    name: "Consult",
    title: "咨询投诉",
    icon: "el-icon-headset",
    component: Layout,
    children: [
      {
        id: "53",
        path: "/consult/consult-complaints",
        title: "咨询投诉",
        name: "ConsultComplaints",
        icon: "el-icon-service",
        component: () => import(/*webpackChunkName: "receipt" */ "@/views/consult/consult-complaints/index.vue"),
      },
    ]
  },
  {
    id: "6",
    path: "/base-info",
    name: "BaseInfo",
    title: "基础信息管理",
    icon: "el-icon-notebook-2",
    component: Layout,
    children: [
      {
        id: "51",
        path: "/base-info/receiver-manage",
        title: "收货方管理",
        name: "ReceiverManage",
        icon: "el-icon-notebook-1",
        component: () => import(/*webpackChunkName: "base-info" */ "@/views/base-info/receiver-manage/index.vue"),
      },
      {
        id: "52",
        path: "/base-info/address-manage",
        title: "地址库管理",
        name: "AddressManage",
        icon: "el-icon-collection",
        component: () => import(/*webpackChunkName: "base-info" */ "@/views/base-info/address-manage/index.vue"),
      },
    ]
  },
  // {
  //   id: "7",
  //   path: "/menu",
  //   name: "menu",
  //   component: Layout,
  //   children: [
  //     {
  //       id: "71",
  //       path: "/menu/menuManage",
  //       title: "菜单管理",
  //       name: "MenuManage",
  //       icon: "el-icon-menu",
  //       component: () => import(/*webpackChunkName: "menuManage" */ "@/views/menu-manage/index.vue"),
  //     },
  //   ]
  // },
  {
    id: "35",
    path: "/common",
    name: "Common",
    component: Layout,
    hidden: true,
    children: [
      {
        id: "10",
        path: "/common/emptyPage",
        title: "空白页面",
        name: "EmptyPage",
        icon: "el-icon-s-home",
        hidden: true,
        component: () => import(/*webpackChunkName: "emptyPage" */ "@/views/empty-page/index.vue"),
      },
    ]
  },


  {
    id: "1",
    path: "/",
    redirect: "login",
    hidden: true
  },
  {
    id: "2",
    path: "/login",
    name: "Login",
    component: Login,
    hidden: true
  },
  // {
  //   id: "8",
  //   path: "/userInfo",
  //   title: "个人中心",
  //   name: "UserInfo",
  //   icon: "el-icon-s-home",
  //   hidden: true,
  //   component: () => import(/*webpackChunkName: "userInfo" */ "@/views/user-info/index.vue"),
  // }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
// 全局守卫
// router.beforeEach((to, from, next) => {
//   //todos:判断是否有token,有继续跳转，无跳转至登陆页
// });
export default router;
export { routes };
