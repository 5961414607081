import request from '@/utils/http.js'

/**
 * 登录
 * @param {object} data 
 * @returns 
 */
export const loginByUserName = data => {
  return request({
    url: '/user/oms/login',
    method: 'post',
    data
  })
}
