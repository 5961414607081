import request from '@/utils/http.js'

/**
 * 地址库管理分页查询
 * @param {object} data 
 * @returns 
 */
export const getWayBillInfo = data => {
  return request({
    url: '/biz/plan/oms/waybillInformation',
    method: 'get',
    params: data
  })
}

/**
 * 近一年的各省运量分布
 * @param {object} data 
 * @returns 
 */
export const freightVolumeDistribution = data => {
  return request({
    url: '/biz/plan/oms/freightVolumeDistribution',
    method: 'get',
    params: data
  })
}

export const getTable4ExcepitonInfo = data => {
  return request({
    url: '/biz/plan/oms/anomalousEvent',
    method: 'get',
    params: data
  })
}

/**
 * 首页运输结构
 * @param {object} data 
 * @returns 
 */
export const transportationStructure = data => {
  return request({
    url: '/biz/plan/oms/transportationStructure',
    method: 'get',
    params: data
  })
}

/**
 * 首页异常事件
 * @param {object} data 
 * @returns 
 */
export const anomalousEvent = data => {
  return request({
    url: '/biz/plan/oms/anomalousEvent',
    method: 'get',
    params: data
  })
}

/**
 * 查询近一年中每个月的零担总数
 * @param {object} data 
 * @returns 
 */
export const zeroCarloadVolumeTrend = data => {
  return request({
    url: '/biz/plan/oms/zeroCarloadVolumeTrend',
    method: 'get',
    params: data
  })
}