<template>
  <div class="login">
    <div
      class="background"
      v-if="!!code"
      v-loading.fullscreen.lock="fullscreenLoading"
    ></div>
    <div class="content" v-else>
      <!-- <img src="@/assets/images/5.png" alt="" class="login-logo" /> -->
      <div class="login-logo">协同&emsp;适配&emsp;便利&emsp;敏捷 </div>
      <div class="login-rt">
        <img src="@/assets/img/logo.png" alt="" class="login-logo1" />
        <div class="login-rt-content">
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="100px"
            class="demo-ruleForm"
          >
            <!-- <div class="content-title">运满多OMS系统</div> -->
           <div class="content-title">柳橙科技OMS系统</div>
            <div class="content-title1">Order Management System</div>
            <!-- <div class="content-title">管理后台</div> -->
            <el-form-item prop="userName">
              <el-input
                v-model.trim="form.userName"
                clearable
                auto-complete="new-username"
              >
                <i
                  slot="prefix"
                  style="font-size: 25px; color: #888"
                  class="el-input__icon el-icon-user-solid"
                ></i
              ></el-input>
            </el-form-item>
            <el-form-item prop="passWord">
              <el-input
                v-model.trim="form.passWord"
                show-password
                clearable
                auto-complete="new-password"
                @keyup.enter.native="submit"
              >
                <i
                  slot="prefix"
                  style="font-size: 25px; color: #888"
                  class="el-input__icon el-icon-lock"
                ></i
              ></el-input>
            </el-form-item>
            <div class="remember-password">
              <el-checkbox
                v-model="isRememberPassword"
                @change="setRememberPassword"
              >记住密码</el-checkbox>
              <el-tooltip class="tip" effect="dark" content="非工作电脑请勿保存" placement="right">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </div>

          </el-form>
          <el-button
            type="primary"
            class="login-btn"
            :loading="logining"
            @click="submit"
            @keyup.enter="submit"
            >登录</el-button
          >
        </div>
      </div>
    </div>
    <div class="copyright">
     <p>Copyright © 柳橙科技物流有限公司, All Rights Reserved.</p>
     <p>杭州柳橙科技物流有限公司</p>
      <!-- <p>Copyright © 运满多网络科技, All Rights Reserved.</p>
      <p>浙江运满多网络科技有限公司</p> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { AES_ECB_ENCRYPT, AES_ECB_DECRYPT } from '@/utils/util.js'
import { loginByUserName } from '@/api/login.js'
import { setToken } from '@/utils/auth.js'


export default {
  layout: 'empty',
  data() {
    console.log('LOGIN_USERNAME: ', process.env.PROXY_URL)
    return {
      logining: false,
      form: {
        userName: '',
        passWord: ''
      },
      rules: {
        userName: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        passWord: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      },
      fullscreenLoading: true,
      code: this.$route.query.code || '',
      isRememberPassword: window.localStorage.getItem('isRememberPassword') === 'true', // 是否记住密码
    }
  },

  computed: {

  },
  watch: {
    isRememberPassword(newVal) {
      window.localStorage.setItem('isRememberPassword', newVal)
    }
  },

  created() {
    if(window.localStorage.getItem('user')) {
      const [userName, passWord] = window.localStorage.getItem('user').split(',')
      this.form.userName = userName
      this.form.passWord = AES_ECB_DECRYPT(passWord, process.env.VUE_APP_SECRET_KEY)
    }
  },
  beforeDestroy () {
    window.localStorage.setItem('isRememberPassword', this.isRememberPassword)
  },

  methods: {
    ...mapActions({
      login: 'login',
      // getLoginToken: 'user/getLoginToken',
      // getUserInfo: 'user/getUserInfoByToken',
    }),
    ...mapMutations({
      setUserInfo: 'SET_USER_INFO'
    }),

    async getToken() {
      try {
        const loginStatus = await this.getLoginToken(this.$route.query)
        if (!loginStatus || loginStatus.code) {
          this.fullscreenLoading = false
          return
        }
        const getUserInfoStatus = await this.getUserInfo()
        if (getUserInfoStatus) {
          this.$message.success('登录成功！')
          this.$router.push('/')
        } else {
          this.$message.success('网络异常，请重试')
        }
      } catch (err) {
        console.error(err)
      }
    },

    async submit() { // 登录

      try {
        await this.$refs.form.validate()

        const res = await this.login(this.form)
        // console.log(res)
        this.$message.success('登录成功！')
        this.setUserInfo = res.data.baseConsignor

        window.localStorage.setItem('userInfo', JSON.stringify(res.data.baseConsignor))
        const password = AES_ECB_ENCRYPT( this.form.passWord , process.env.VUE_APP_SECRET_KEY)
        if(this.isRememberPassword) {
          window.localStorage.setItem('user', this.form.userName + ',' + password)
        } else {
          window.localStorage.setItem('user', '')
        }
        this.$router.push('/layout/home')

      } catch (err) {
        console.log(err)
      }
    },
    // 记住密码
    setRememberPassword(val) {
      console.log(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  position: relative;
  background: $primary-color;

  // .background {
  //   width: 70%;
  //   height: 80%;
  //   border: 1px solid red;
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   background: linear-gradient(223deg, #5787c3 0%, #39629c 100%);
  //   opacity: 0.4;
  // }

  .content {
    width: 1000px;
    height: 80%;
    min-height: 500px;
    max-height: 600px;
    position: absolute;
    top: 5%;
    // bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    // border-radius: 8px;
    // box-shadow: 0px 2px 20px 0px #e2eeff;
    // background: #ffffff;
    display: flex;

    .login-logo1 {
      height: 20%;
      margin-top: 4%;
      margin-bottom: 2%;
    }

    .login-logo {
      width: 500px;
      height: 500px;
      color: #ffffff;
      font-size: 36px;
      line-height: 500px;

    }

    .login-rt {
      width: 450px;
      height: 100%;
      position: relative;
      box-shadow: 0px 2px 20px 0px #e2eeff;
      border-radius: 15px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;

      .login-rt-content {
        width: 80%;
        height: 100%;

        ::v-deep .el-form-item {
          display: flex;
          flex-direction: column;
          .el-form-item__content {
            margin-left: 0 !important;
            margin-bottom: 10px;
            .el-input__inner {
              border: none;
              border-bottom: 1px solid #d4d4d4;
              padding-left: 55px;
            }
          }
        }

        .content-title {
          // height: 90px;
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: $primary-color;
          line-height: 8vh;
          text-align: center;
          // margin-bottom: 25px;
          // text-align: center;
          // font-size: 32px;
          // font-weight: 500;
          // color: #2d303b;
          // margin-bottom: 60px;
        }
        .content-title1 {
          // height: 90px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: $primary-color;
          line-height: 5vh;
          margin-bottom: 3vh;
          text-align: center;
          // text-align: center;
          // font-size: 32px;
          // font-weight: 500;
          // color: #2d303b;
          // margin-bottom: 60px;
        }

        .input-style {
          margin-bottom: 40px;

          p {
            color: #2d303b;
          }

          input {
            width: 100%;
            height: 35px;
            line-height: 35px;
            // background: transparent;
            border: none;
            border-bottom: 1px solid #d4d4d4;
          }
        }

        .login-btn {
          width: 100%;
          height: 60px;
          margin-top: 2.5%;
        }
      }
    }
  }
  .copyright {
    width: 100%;
    height: 8%;
    position: absolute;
    // top: 720px;
    bottom: 4%;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 12px;
      color: #cccccc;
      margin: 5px;
    }
  }
  .remember-password {
    // position: absolute;
    // left: 0;
    // bottom: 10px;
    display: flex;
    align-items: center;
    .tip {
      margin-left: 10px;
    }
  }
}
</style>
